<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">直播参数</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stu == "edit" ? "修改" : "新增" }}直播参数</a
          >
        </span>
      </div>
      <div class="framePage-body addClassSubject">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexcc">
              <el-form
                ref="Form"
                :model="Form"
                :rules="rules"
                label-width="13rem"
              >
                <el-form-item label="行政区划" prop="areaId" class="form-item">
                  <el-input
                    v-if="Form.areaId == '-1000'"
                    v-model="areaName"
                    disabled
                    style="width: 250px"
                  />
                  <el-cascader
                    v-else
                    v-model="Form.areaId"
                    style="width: 250px"
                    :options="areatreeList"
                    :props="propsarea"
                    clearable
                    filterable
                    size="small"
                  ></el-cascader>
                </el-form-item>
                <el-form-item
                  label="培训类型"
                  prop="trainTypeId"
                  class="form-item"
                >
                  <el-input
                    v-if="Form.trainTypeId == '-1000'"
                    v-model="trainTypeName"
                    disabled
                    style="width: 250px"
                  />
                  <el-cascader
                    v-else
                    style="width: 250px"
                    size="small"
                    :props="{
                      emitPath: false,
                      value: 'id',
                      label: 'label',
                      children: 'children',
                      checkStrictly: true,
                    }"
                    v-model="Form.trainTypeId"
                    :options="trainTypeList"
                    clearable
                    @change="handleTrainType"
                  ></el-cascader>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item
                  prop="liveBroadcastBefore"
                  class="form-item"
                  label="是否直播前签到"
                >
                  <el-radio-group v-model="Form.liveBroadcastBefore">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider v-if="Form.liveBroadcastBefore"></el-divider>
                <el-form-item
                  prop="liveBroadcastSignIn"
                  class="form-item"
                  label="是否每次直播签到"
                  v-if="Form.liveBroadcastBefore"
                >
                  <el-radio-group v-model="Form.liveBroadcastSignIn">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="随机认证">
                  <el-radio-group
                    @change="handleparaIsPopQuestion"
                    v-model="Form.randomAuthentication"
                  >
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  prop="paraPopTimeLimit"
                  class="form-item"
                  label="随机认证最小间隔"
                  v-show="Form.randomAuthentication"
                >
                  <el-input-number
                    v-model="Form.randomAuthenticationLimit"
                    :step="1"
                    :min="1"
                    :precision="0"
                    size="small"
                  ></el-input-number>
                  <span style="margin-left: 10px">分钟</span>
                </el-form-item>
                <el-form-item
                  prop="paraPopNum"
                  class="form-item"
                  label="每节直播课认证次数"
                  v-show="Form.randomAuthentication"
                >
                  <el-input-number
                    v-model="Form.randomAuthenticationPopNum"
                    :step="1"
                    :min="1"
                    :precision="0"
                    size="small"
                  ></el-input-number>

                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item
                  prop="liveBroadcastSignOut"
                  class="form-item"
                  label="是否结束后签退"
                >
                  <el-radio-group v-model="Form.liveBroadcastSignOut">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item
                  prop="liveBroadcastSignOut"
                  class="form-item"
                  label="人脸认证停留时长"
                >
                  <el-input
                    placeholder="请输入人脸认证停留时长"
                    v-model="Form.waitingTime"
                    size="small"
                    style="width: 200px"
                  >
                    <template slot="append">秒</template>
                  </el-input>
                </el-form-item>

                <el-divider></el-divider>
                <el-form-item
                  prop="paraLiveRoll"
                  class="form-item"
                  label="直播监管点名"
                >
                  <el-radio-group v-model="Form.paraLiveRoll">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>

                <template v-if="Form.paraLiveRoll">
                  <el-form-item class="form-item" label="监管点名默认参数设置">
                  </el-form-item>

                  <el-form-item
                    prop="paraLiveRollSuperviseInterval"
                    class="form-item"
                    label="最小间隔"
                  >
                    <el-input
                      placeholder="请输入最小间隔"
                      v-model="Form.paraLiveRollSuperviseInterval"
                      size="small"
                      style="width: 200px"
                    >
                      <template slot="append">分钟</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item
                    prop="paraLiveRollSuperviseCount"
                    class="form-item"
                    label="认证次数"
                  >
                    <el-input
                      placeholder="请输入认证次数"
                      v-model="Form.paraLiveRollSuperviseCount"
                      size="small"
                      style="width: 200px"
                    >
                      <template slot="append">次</template>
                    </el-input>
                  </el-form-item>
                </template>

                <el-divider></el-divider>
                <el-form-item
                  prop="paraLiveRollCompEnable"
                  class="form-item"
                  label="机构点名默认参数设置"
                >
                  <el-radio-group v-model="Form.paraLiveRollCompEnable">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item
                  v-if="Form.paraLiveRollCompEnable"
                  prop="paraLiveRollCompInterval"
                  class="form-item"
                  label="最小间隔"
                >
                  <el-input
                    placeholder="请输入最小间隔"
                    v-model="Form.paraLiveRollCompInterval"
                    size="small"
                    style="width: 200px"
                  >
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>

                <el-form-item
                  v-if="Form.paraLiveRollCompEnable"
                  prop="paraLiveRollCompCount"
                  class="form-item"
                  label="认证次数"
                >
                  <el-input
                    placeholder="请输入认证次数"
                    v-model="Form.paraLiveRollCompCount"
                    size="small"
                    style="width: 200px"
                  >
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>

              </el-form>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">{{
                Form.trainTypeId != "-1000" && Form.areaId != "-1000"
                  ? "取消"
                  : "返回"
              }}</el-button>
              <el-button @click="seveData('ruleForm')" class="bgc-bv"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "SupervisionEnd/addClassSubject",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    return {
      stu: "",
      areaName: "",
      trainTypeName: "",
      areatreeList: [],
      datatreeList: [], //培训类型
      LearningstateList: [], //人群类型
      editJson: {},
      crodType: "",
      params: {},
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        areaId: "",
        trainTypeId: "",
        liveBroadcastBefore: false,
        liveBroadcastSignIn: false,
        randomAuthentication: false,
        randomAuthenticationLimit: 10,
        randomAuthenticationPopNum: 3,
        liveBroadcastSignOut: false,
        waitingTime: "30",
        paraLiveRoll: true, // 直播监管点名
        paraLiveRollCompEnable: false, // 机构点名默认参数设置
        paraLiveRollCompInterval: 10, // 直播机构点名最小间隔
        paraLiveRollCompCount: 3, // 直播机构点名认证次数
        paraLiveRollSuperviseCount: 3, // 直播监管点名认证次数
        paraLiveRollSuperviseInterval: 10, // 直播监管点名最小间隔
      },
      trainTypeList: [], // 培训类型
      rules: {
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],

        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getCrowdtypeList();
    this.getareatree();
    this.getTraintype();
    this.getLearningstateList();
    this.id = this.$route.query.paraId;
    this.stu = this.$route.query.stu;
    if (this.$route.query.stu == "edit") {
      this.getDetail(this.id);
    } else {
      if (this.Form.obj) {
        console.log(this.Form.obj);
        this.Form.paraTimeLimit.push({ ...this.Form.obj });
      }
    }
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    paraIsLoginTipsChange(val) {
      if (val == false) {
        this.Form.paraRandomTimeMin = "";
        this.Form.paraRandomNum = "";
        this.Form.paraTime = "";
        this.Form.paraIsRandom = false;
      }
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getLearningstateList() {
      const LearningstateList = this.$setDictionary("CROWDTYPE", "list");
      const list = [];
      for (const key in LearningstateList) {
        list.push({
          value: key,
          label: LearningstateList[key],
        });
      }
      this.LearningstateList = list;
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const list = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      this.CrowdtypeList = list;
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let parmar = {
        areaId: this.Form.areaId,
        trainTypeId: this.Form.trainTypeId,
        mustSignIn: this.Form.liveBroadcastBefore,
        mustEverySignId:
          this.Form.liveBroadcastBefore == false
            ? false
            : this.Form.liveBroadcastSignIn,
        mustRandom: this.Form.randomAuthentication,
        randomTimeMin: this.Form.randomAuthentication
          ? this.Form.randomAuthenticationLimit
          : 0,
        randomNum: this.Form.randomAuthentication
          ? this.Form.randomAuthenticationPopNum
          : 0,
        mustSignOut: this.Form.liveBroadcastSignOut,
        waitingTime: this.Form.waitingTime,
        paraLiveRoll: this.Form.paraLiveRoll, // 直播监管点名
        paraLiveRollCompEnable: this.Form.paraLiveRollCompEnable, // 机构点名默认参数设置
        paraLiveRollCompInterval: this.Form.paraLiveRollCompInterval, // 直播机构点名最小间隔
        paraLiveRollCompCount: this.Form.paraLiveRollCompCount, // 直播机构点名认证次数
        paraLiveRollSuperviseCount: this.Form.paraLiveRollSuperviseCount, // 直播监管点名认证次数
        paraLiveRollSuperviseInterval: this.Form.paraLiveRollSuperviseInterval, // 直播监管点名认证次数
      };
      if (this.stu == "edit") {
        parmar.paraId = this.id;
      }

      this.$post(
        this.id
          ? "/parameter/live-parameter/modify"
          : "/parameter/live-parameter/insert",
        parmar,
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/operate/liveBroadcastParametersList",
                query: {
                  refrsh: true,
                },
              });
            }, 300);
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    getDetail(paraId) {
      this.$post(
        "/parameter/live-parameter/info",
        { paraId },
        3000,
        true,
        3
      ).then((res) => {
        if (res.status == "0") {
          this.Form = {
            ...res.data,
            liveBroadcastBefore: res.data.mustSignIn,
            liveBroadcastSignIn: res.data.mustEverySignId,
            randomAuthentication: res.data.mustRandom,
            randomAuthenticationLimit: res.data.randomTimeMin,
            randomAuthenticationPopNum: res.data.randomNum,
            liveBroadcastSignOut: res.data.mustSignOut,
          };
          this.areaName = "默认";
          this.trainTypeName = "默认";
        }
      });
    },
    /* 特殊完成条件Change事件 */
    // handleSpecialConditions(val) {
    //   this.Form.paraSpecialConditions = val
    //   if(this.Form.paraSpecialConditions == false) {
    //     this.ProgressDisabled = true;
    //     this.Form.paraProgress = '80';
    //   } else {
    //       this.ProgressDisabled = false;
    //   }
    // }
  },
};
</script>
<style lang="less">
</style>
<style lang="less" >
.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 100%;
      padding-left: 10rem;
    }
    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
  .paraCompleteConditionInput {
    display: inline-block;
    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }
}
</style>
